
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';

    export const meta: ComponentMeta = {
        title: 'Контакты. Текст',
        options: {
            items: {
                title: 'Колонки с текстом',
                type: AvailableTypes.array,
                limit: 4,
                item: {
                    subtitle: {
                        title: 'Подзаголовок',
                        type: AvailableTypes.editor
                    },
                    text: {
                        title: 'Абзац',
                        type: AvailableTypes.editor
                    }
                },
                default: [
                    {
                        subtitle: 'Адрес',
                        text:
                            '111622, г. Москва, ул. Большая Косинская, д. 27, оф. 1013, БЦ<br>' +
                            '«Косинская мануфактура» (территория бывшей трикотажной<br>' +
                            'фабрики)'
                    },
                    {
                        subtitle: 'Телефон',
                        text: '+7 (495) 215 55 32'
                    },
                    {
                        subtitle: 'Почта',
                        text: 'pinq@pinq.ru'
                    },
                    {
                        subtitle: 'Часы работы',
                        text: 'с 09:30 до 18:00<br>' + 'по Москве'
                    }
                ]
            }
        },
        group: AvailableGroups.Тексты
    };

    @Component({
        components: { OptionsFieldsInlineEditor }
    })
    export default class ContactsText extends Vue {
        @Prop() items;
        @Prop() component;
    }
