
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta,
        ComponentMetaOptionArrayOfType,
        ComponentMetaOptionSelect
    } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';

    interface ComponentMetaColumns extends ComponentMeta {
        options: {
            columns: ComponentMetaOptionSelect;
            items: ComponentMetaOptionArrayOfType;
        };
    }

    export const meta: ComponentMetaColumns = {
        title: 'Колонки',
        options: {
            columns: {
                title: 'Ширина колонки',
                type: AvailableTypes.select,
                values: [
                    { selected: true, text: '6', value: 6 },
                    { selected: false, text: '4', value: 4 },
                    { selected: false, text: '3', value: 3 }
                ]
            },
            items: {
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.editor
                },
                default: [
                    'Канвас — размещении заказа возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей.',
                    'Эти и другие вопросы можно решить \n' +
                        'с помощью конструкции и модельных особенностей футболки. Возникают следующие проблемы: вы не знаете размерный ряд или \n' +
                        'он слишком большой, или нужны уникальные футболки.'
                ]
            }
        },
        group: AvailableGroups.Тексты
    };
    @Component({
        components: { OptionsFieldsInlineEditor }
    })
    export default class Columns extends Vue {
        @Prop() columns;
        @Prop() items;
        @Prop() component;
        margin = 1;
    }
