
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import WindowMixin from '@/mixins/windowMixin';
    import PaletteMixin from '@/mixins/paletteMixin';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export interface ItemList {
        title: string;
        href: string;
        img: Image[];
        text: string;
    }

    export interface Image {
        src: string;
        title?: string;
        alt?: string;
    }

    export const meta = {
        title: 'Польза, экспертиза, статьи ',
        options: {
            title: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Польза, экспертиза, статьи'
            },
            items: {
                title: 'Работы',
                type: AvailableTypes.array,
                item: {
                    img: { type: AvailableTypes.image, title: 'Картинка' },
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    href: {
                        type: AvailableTypes.string,
                        title: 'Ссылка для заголовка'
                    },
                    hrefTargetBlank: {
                        type: AvailableTypes.boolean,
                        title: 'Открывать ссылку для заголовка в новом окне'
                    },
                    catalog: {
                        type: AvailableTypes.string,
                        title: 'Имя раздела'
                    },
                    catalogHref: {
                        type: AvailableTypes.string,
                        title: 'Ссылка для раздела'
                    },
                    catalogHrefTargetBlank: {
                        type: AvailableTypes.boolean,
                        title: 'Открывать ссылку для раздела в новом окне'
                    },
                    text: {
                        type: AvailableTypes.string,
                        title: 'Текст'
                    }
                },
                default: optionsDefault.ExpertiseAndArticles
            }
        },
        group: AvailableGroups.Плитка
    };

    @Component({
        components: { PaletteMixin, ImageComponent }
    })
    export default class ExpertiseAndArticles extends mixins(WindowMixin, PaletteMixin) {
        @Prop(String) title;
        @Prop(Array) items: ItemList[];

        getHrefTarget(item) {
            return item.hrefTargetBlank ? '_blank' : '_self';
        }

        getCatalogHrefTarget(item) {
            return item.catalogHrefTargetBlank ? '_blank' : '_self';
        }
    }
