
    import { mixins, Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import accordionMixin from '@/mixins/accordionMixin';
    import WindowMixin from '@/mixins/windowMixin';
    import QuestionCollapse from '@/components/question/QuestionCollapse.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { PageComponent } from '@/store/page';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import striptags from 'striptags';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';

    export const meta = {
        title: 'Контакты - как добраться',
        options: {
            title: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Как добраться'
            },
            items: {
                title: 'Маршруты',
                type: AvailableTypes.array,
                item: {
                    route: {
                        type: AvailableTypes.editor,
                        title: 'Название маршрута'
                    },
                    description: {
                        type: AvailableTypes.editor,
                        title: 'Описание'
                    },
                    mapLink: {
                        type: AvailableTypes.string,
                        title: 'Ссылка на карту'
                    }
                },
                default: optionsDefault.ContactsMaps
            }
        },
        group: AvailableGroups.Список
    };

    @Component({
        components: {
            QuestionCollapse,
            OptionsFieldsInlineEditor
        }
    })
    export default class ContactsMaps extends mixins(
        PageBlockMixin,
        accordionMixin,
        WindowMixin
    ) {
        @Prop(String) title;
        @Prop() component: PageComponent;
        @Prop() items;
        @Prop() finishPoint;

        striptags = striptags;

        currentElement = 0;
        bgIsVisible = false;

        mounted() {
            this.getBackgroundOnMapLoad();
        }

        changeCurrentElement(index) {
            if (index === this.currentElement) {
                return false;
            }

            this.getBackgroundOnMapLoad();
            return (this.currentElement = index);
        }

        getBackgroundOnMapLoad() {
            if (this.bgIsVisible) {
                return (this.bgIsVisible = false);
            }

            const map = this.$refs.map;
            // @ts-ignore
            map.onload = () => {
                this.bgIsVisible = true;
            };

            return this.bgIsVisible;
        }
    }
