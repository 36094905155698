
    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let result = '';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    function wrapCssWithClass(cssString, className) {
        if (!cssString) {
            return cssString;
        }
        return cssString.replace(/(^|\})\s*([^{]+)/g, (match, p1, p2) => {
            // Add the class name as a prefix to all selectors
            return `${p1} .${className} ${p2.trim()}`;
        });
    }

    import { Component, Vue, Watch } from 'nuxt-property-decorator';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '../editor/config/types';
    import { Prop } from 'nuxt-property-decorator';

    export const meta: ComponentMeta = {
        options: {
            userProps: {
                title: 'Свойства',
                type: AvailableTypes.array,
                item: {
                    code: {
                        type: AvailableTypes.string
                    },
                    value: {
                        type: AvailableTypes.string
                    }
                }
            },
            template: {
                description: 'Свойства доступны в объекте data',
                title: 'Шаблон',
                language: 'html',
                type: AvailableTypes.code
            },
            container: {
                type: AvailableTypes.object,
                title: 'Контейнер',
                item: {
                    useContainer: {
                        title: 'Использовать контейнер',
                        type: AvailableTypes.boolean,
                        default: false
                    },
                    center: {
                        title: 'Центрировать контейнер',
                        type: AvailableTypes.boolean,
                        default: false
                    },
                    containerMaxWidth: {
                        title: 'Максимальная ширина контейнера',
                        type: AvailableTypes.string
                    },
                    containerMaxHeight: {
                        title: 'Максимальная высота контейнера',
                        type: AvailableTypes.string
                    }
                }
            },
            styles: {
                title: 'CSS',
                language: 'css',
                type: AvailableTypes.code
            },
            images: {
                title: 'Картинки',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.image
                }
            },
            files: {
                title: 'Файлы',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.file
                }
            }
        },
        group: AvailableGroups.Другое,
        title: 'Свой компонент',
        style: {
            'padding-bottom': '2rem'
        }
    };

    @Component({
        components: {}
    })
    export default class CustomComponent extends Vue {
        @Prop({ default: '' }) template;
        @Prop() userProps;
        @Prop() styles;
        @Prop() container;
        @Prop({
            default: () => []
        })
        data = {};
        renderedTemplate = '';
        renderedStyles = '';
        id = generateRandomString(10);

        created() {
            this.onChangeUserProps();
        }

        @Watch('styles', { deep: true })
        onChangeStyles() {
            this.renderedStyles = wrapCssWithClass(this.styles, this.id);
        }

        @Watch('userProps', { deep: true })
        onChangeUserProps() {
            if (this.userProps) {
                this.userProps.forEach(prop => {
                    this.data[prop.code] = this.parseJson(prop.value);
                });
            }

            this.renderedTemplate = this.template;

            this.renderedTemplate = this.renderedTemplate.replace(
                /\{(\w+)\}/g,
                (match, variable) => {
                    return this.data[variable] !== undefined
                        ? this.data[variable]
                        : match;
                }
            );
            this.renderedStyles = wrapCssWithClass(this.styles, this.id);
        }

        @Watch('template', { deep: true })
        onChangeTemplate() {
            this.onChangeUserProps();
        }

        @Watch('container', { deep: true })
        onChangeContainer() {
            this.onChangeUserProps();
        }

        @Watch('template', { deep: true })
        onChangeImages() {
            this.onChangeUserProps();
        }

        parseJson(value) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        }
    }
