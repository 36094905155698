
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import BreadCrumbs from '@/components/blocks/BreadCrumbs.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';

    export const meta: ComponentMeta = {
        title: 'Контакты',
        options: {
            hidePath: {
                title: 'Скрывать хлебные крошки',
                type: AvailableTypes.boolean,
                default: false
            },
            head: {
                title: 'Заголовок',
                type: AvailableTypes.editor,
                default: 'Контакты'
            },
            tabs: {
                title: 'Вкладки',
                type: AvailableTypes.array,
                item: {
                    title: {
                        title: 'Заголовок таба',
                        type: AvailableTypes.string,
                        default: 'Как добраться'
                    },
                    link: {
                        title: 'Ссылка',
                        type: AvailableTypes.string,
                        default: ''
                    },
                    targetBlank: {
                        title: 'Открывать ссылку в новом окне',
                        type: AvailableTypes.boolean,
                        default: false
                    }
                }
            }
        },
        group: AvailableGroups.Вкладки
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            BreadCrumbs
        }
    })
    export default class ContactsTabs extends Vue {
        @Prop() component;
        @Prop() head;
        @Prop() tabs;
        @Prop() hidePath;

        get justifyTabs() {
            return this.tabs.length > 5
                ? 'justify-content-xl-start'
                : 'justify-content-xl-center';
        }
    }
