
    import { Component, Prop, namespace, Vue } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    // @ts-ignore
    import BaseHeader from '@/components/common/BaseHeader';
    import { IGroup } from '@/store/page';

    const pages = namespace('pages');

    export const meta = {
        title: 'Хедер',
        options: {
            logo: {
                title: 'Логотип - текст',
                type: AvailableTypes.string,
                default: 'PINQ'
            },
            logoHref: {
                title: 'Логотип - ссылка',
                type: AvailableTypes.string,
                default: '/'
            },
            logoImage: {
                title: 'Логотип - изображение',
                type: AvailableTypes.image
            },
            phone: {
                title: 'Телефон',
                type: AvailableTypes.string,
                default: '+7 (495) 215 55 32'
            },
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default: 'Вы можете уже сейчас сделать заказ!'
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        style: { height: '8rem', position: 'relative', 'z-index': 4 },
        group: AvailableGroups.Хедер
    };

    type Icon = { src: string };

    interface IMenuLink {
        name: string;
        href: string;
    }

    interface ISubmenuItem {
        icon?: Icon;
        head: IMenuLink;
        links: IMenuLink[];
    }

    interface IMenuItem extends IMenuLink {
        submenu?: ISubmenuItem[];
    }

    interface Group extends IGroup {
        id: string;
    }

    @Component({
        components: { BaseHeader }
    })
    export default class DynamicHeader extends Vue {
        @Prop() component;
        @Prop() logo;
        @Prop() logoImage;
        @Prop() logoHref;
        @Prop() phone;
        @Prop() text;
        @Prop() form;

        @pages.State groups: Group[];
        @pages.State pages;
        @pages.Action fetchAllGroups;
        @pages.Action fetchAllPages;

        items: IMenuItem[] = [];

        async created() {
            await Promise.all([this.fetchAllGroups(), this.fetchAllPages()]);

            const newItems: IMenuItem[] = [];

            const rootGroups =
                this.groups &&
                this.groups
                    .filter(
                        ({ options: { parent, showInMenu } }) =>
                            !parent && showInMenu === 'true'
                    )
                    .sort((a, b) => a.options.order - b.options.order);

            rootGroups &&
                rootGroups.forEach(rootGroup => {
                    const submenuItems = this.getSubmenuItems(rootGroup);

                    newItems.push({
                        name: rootGroup.options.name,
                        href: `/${rootGroup.options.slug}`,
                        submenu: submenuItems
                    });
                });

            this.items = newItems;
        }

        getSubmenuItems(rootGroup: Group): ISubmenuItem[] {
            const submenuItems: ISubmenuItem[] = [];

            this.groups
                .filter(
                    group =>
                        group.options.parent === rootGroup.id &&
                        group.options.showInMenu === 'true'
                )
                .sort((a, b) => a.options.order - b.options.order)
                .forEach(group => {
                    let submenuFromRoot: boolean;
                    const links: IMenuLink[] = [];

                    this.pages.forEach(
                        ({
                            options: {
                                group: pageGroup,
                                showInMenu,
                                title,
                                slug,
                                buildUrlFromRoot
                            }
                        }) => {
                            if (pageGroup) {
                                if (pageGroup.id === rootGroup.id && buildUrlFromRoot) {
                                    submenuFromRoot = true;
                                }

                                if (showInMenu && pageGroup.id === group.id) {
                                    links.push({
                                        name: title,
                                        href: buildUrlFromRoot
                                            ? slug
                                            : `/${rootGroup.options.slug}/${group.options.slug}/${slug}`
                                    });
                                }
                            }
                        }
                    );

                    submenuItems.push({
                        head: {
                            name: group.options.name,
                            // @ts-ignore
                            href: submenuFromRoot
                                ? group.options.slug
                                : `/${rootGroup.options.slug}/${group.options.slug}`
                        },
                        links
                    });
                });

            return submenuItems;
        }
    }
