
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import Button from '@/components/blocks/Button.vue';
    import { BCol } from 'bootstrap-vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import BaseColorsModal from '@/components/blocks/BaseColorsModal.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Цвета',
        options: {
            image: {
                type: AvailableTypes.image,
                title: 'Картинка',
                default: {
                    src: '/block-default/Colors/base-colors.jpg'
                }
            },
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Базовые цвета'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос. Мы подберем вам наиболее подходящий. Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос. Мы подберем вам наиболее подходящий.'
            },
            innerComponent: {
                type: AvailableTypes.component,
                componentName: 'BaseColorsModal'
            }
        },
        group: AvailableGroups.Цвета
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            BaseColorsModal,
            Button,
            BCol,
            ImageComponent
        }
    })
    export default class Colors extends mixins(PageBlockMixin) {
        @Prop() image;
        @Prop() modal;
        @Prop() innerComponent;
        @Prop() component;

        click(form) {
            this.$bvModal.show(form);
        }
    }
